








































































































































































import Vue from "vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectCard from "@monorepo/uikit/src/components/common/Select/SelectCard.vue";
import {
  COMPLEXITY_TYPES,
  ISectionElement,
  ITemplateElement,
  IValueElement,
  TYPES,
} from "@monorepo/informationSecurity/src/views/Settings/types/templateElement";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import SettingItemWrap from "@monorepo/uikit/src/components/Settings/SettingItemWrap.vue";
import { defineComponent } from "@vue/composition-api";
import { breadcrumbs } from "@monorepo/informationSecurity/src/views/Settings/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/settingsView/viewTitle";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import Panel from "@monorepo/uikit/src/components/common/Panel.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as SettingsView } from "@monorepo/informationSecurity/src/store/modules/settingsView";
import { mapActions, mapGetters, Module } from "vuex";
import mask from "@monorepo/utils/src/directives/mask";
import { v4 as uuid } from "uuid";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { cloneDeep, isEqual } from "lodash";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import {
  auditTypesToNotify,
  mrdTkTypeCheck,
  smevListCode,
  vipnetListCode,
} from "@monorepo/informationSecurity/src/views/Settings/constants/specialCodes";
import { getFullPath, validateCron } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import axios from "axios";
import moment from "moment";

export default defineComponent({
  name: "SettingsView",
  components: {
    Breadcrumbs,
    Footer,
    Panel,
    TextFilter,
    FormInputElement,
    SelectCard,
    CheckboxElement,
    SettingItemWrap,
  },
  directives: {
    mask,
  },
  data() {
    return {
      TYPES,
      vipnetListCode,
      smevListCode,
      breadcrumbs,
      viewTitle,
      auditTypesToNotify,
      environmentVariables,
      passwordIsShowObject: {} as { [key: string]: boolean },
      openedPanels: [0],
      data: {} as Record<string, string | string[]>,
      defaultData: {} as Record<string, string | string[]>,
      codeData: {} as Record<string, string>,
      key: uuid(),
      filesMetadata: {} as Record<string, unknown>,
      isLoadingIds: {} as Record<string, boolean>,
    };
  },
  computed: {
    ...mapGetters("app", ["isExportFileLoading"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("SettingsView", ["templates", "isTableLoading"]),
    isSpecialCodeForSelect(): (code: string) => boolean {
      return (code: string) => {
        return [vipnetListCode, smevListCode, auditTypesToNotify].includes(code);
      };
    },
    isMultipleSelectType(): (code: string) => boolean {
      return (code: string) => {
        return [auditTypesToNotify].includes(code);
      };
    },
    getFileName(): (id: string) => string {
      return (id: string) => {
        const metaObj = this.filesMetadata[id] as Record<string, string>;
        const createDate = metaObj?.created ? moment(metaObj.created).format("DD.MM.YYYY HH:mm") : "";
        return `${metaObj?.originalName || ""} (загружен ${createDate})`;
      };
    },
    isPassword(): (code: string) => boolean {
      return (code: string) => {
        return ["VIPNET_PASSWORD", "SMTP_PASSWORD", "S3_SECRET_KEY", "ELASTIC_PASSWORD"].includes(code);
      };
    },
    pattern(): (type: TYPES) => string {
      return () => {
        return "";
      };
    },
    complexTypeCodes(): string[] {
      return this.templates.reduce((result: string[], template: ISectionElement) => {
        const complexSettings = template.settings
          .filter((setting) => setting.complexityType === COMPLEXITY_TYPES.COMPLEX)
          .map((setting) => setting.code);
        return result.concat(complexSettings);
      }, []);
    },
    fileSettingsIds(): number[] {
      return this.templates.reduce((result: number[], template: ISectionElement) => {
        const fileSettings = template.settings.filter((setting) => setting.type === TYPES.FILE).map((setting) => setting.id);
        return result.concat(fileSettings);
      }, []);
    },
    isComplexType(): (code: string) => boolean {
      return (code: string) => {
        return this.complexTypeCodes.concat(mrdTkTypeCheck).includes(code);
      };
    },
    isDisabled(): (code: string) => boolean {
      return (code: string) => {
        let setting = null as ITemplateElement | null;
        switch (code) {
          case "SMEV_TSA_URI":
            setting = this.getByCode("VIPNET_TSA_USE");
            return !!this.data[setting?.id || ""];
          case "VIPNET_TSA_URI":
            setting = this.getByCode("SMEV_TSA_USE");
            return !!this.data[setting?.id || ""];
          default:
            return false;
        }
      };
    },
    mask(): (
      type: TYPES,
      code: string
    ) =>
      | {
          regex: string;
        }
      | string {
      return (type: TYPES, code: string) => {
        switch (type) {
          case TYPES.NUMBER:
            return { regex: "[0-9]*" };
          case TYPES.GUID:
            return cloneDeep(standardMask.guid);
          case TYPES.LEFT_DAYS_TIME:
            return { regex: "[0-9]{4}:[0-9]{2}:[0-9]{2}" };
          case TYPES.CRON:
            return cloneDeep(standardMask.cron);
          default:
            return code === "ZONE_ID"
              ? { regex: "[+-]{1}[0-2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}" }
              : ["DEFAULT_START_TRANSFER_DATE", "DEFAULT_END_TRANSFER_DATE"].includes(code)
              ? { regex: "[0-3]{1}[0-9]{1}:[0-1]{1}[0-9]{1}" }
              : "";
        }
      };
    },
    type(): (type: TYPES) => string {
      return (type: TYPES) => {
        return type === TYPES.NUMBER ? "string" : "string";
      };
    },
    isShowFooter(): boolean {
      return !isEqual(this.data, this.defaultData);
    },
    isShowSYSTEM(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_SYSTEM);
    },
    isShowCWS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_CWS);
    },
    isShowDS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_DS);
    },
    isShowRS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_RS);
    },
    isShowDRS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_DRS);
    },
    isShowIRS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_IRS);
    },
    isShowHDUOIS(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_HDUOIS);
    },
    isShowHDStorageCHED(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_HD_STORAGE_CHED);
    },
    isShowIB(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_IB);
    },
    isShowMAA(): boolean {
      return isAuthorityExist(this.user, authorities.SETUP_MAA);
    },
    leftDaysTypeIds(): string[] {
      return this.templates
        ?.reduce((result: ITemplateElement[], item: ISectionElement) => {
          result = result.concat(item.settings);
          return result;
        }, [])
        .filter((item: ITemplateElement) => item.type === TYPES.LEFT_DAYS_TIME)
        .map((item: ITemplateElement) => item.id?.toString());
    },
    isValidLeftDaysValues(): boolean {
      return this.leftDaysTypeIds.map((id: string) => this.data[id]).every((value: string | string[]) => value && !value.includes("_"));
    },
    cronSettings(): string[] {
      return (this.templates || [])
        .reduce((settings: ITemplateElement[], template: ISectionElement) => {
          return settings.concat(template.settings);
        }, [])
        .filter((setting: ITemplateElement) => setting.type === TYPES.CRON)
        .map((setting: ITemplateElement) => this.data[setting.id]);
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("SettingsView", [
      "getDataRequest",
      "saveElements",
      "getVipnetCertificates",
      "getAuditTypesToNotify",
      "getTemplates",
      "checkTsaUri",
      "uploadFileToStorage",
      "getMetadata",
    ]),
    async getFilesMetadata() {
      const promises = this.fileSettingsIds
        .map((id: number) => ({ id, fileGuid: this.data[id] }))
        .filter((item) => !!item.fileGuid)
        .map((item) => {
          return this.getMetadata(item.fileGuid).then((data: any) => {
            Vue.set(this.filesMetadata, item.id, data);
          });
        });
      await Promise.all(promises);
    },
    downloadFile(uuid: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${uuid}&origin=true`);
    },
    async uploadFile(setting: ITemplateElement, file: File | null) {
      if (!file) {
        Vue.set(this.data, setting.id, this.defaultData[setting.id]);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("attr", JSON.stringify([{ attribute: "OBJECT_TYPE", value: "ELASTIC_SYNONYM_FILE" }]));
      formData.append("zipping", "false");
      const data = await this.uploadFileToStorage(formData);
      if (data?.data?.guid) {
        Vue.set(this.data, setting.id, data.data.guid);
      }
    },
    async clickButton(id: string) {
      const urlArray = (this.data[id] as string)?.split("/").filter((item) => !!item);
      const envVariable = urlArray?.[0] ? Object.entries(environmentVariables).find((item) => item[1].includes(urlArray[0]))?.[0] : null;
      if (!this.data[id] || !envVariable) {
        return;
      }
      const resultPath = [environmentVariables[envVariable as keyof typeof environmentVariables]].concat(urlArray.slice(1)).join("/");
      try {
        Vue.set(this.isLoadingIds, id, true);
        await axios.post(resultPath);
        showNotification("Запрос успешно выполнен", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        console.error(e);
        showNotification(e?.response?.data?.error?.text || e?.response?.data?.message || "Ошибка выполнения запроса");
      } finally {
        Vue.set(this.isLoadingIds, id, false);
      }
    },
    onChangeCheckbox(setting: ITemplateElement, value: boolean) {
      if (!value) {
        return;
      }
      if (["SMEV_TSA_USE", "VIPNET_TSA_USE"].includes(setting.code)) {
        const secondSetting = this.getByCode(setting.code === "SMEV_TSA_USE" ? "VIPNET_TSA_USE" : "SMEV_TSA_USE");
        Vue.set(this.data, secondSetting?.id || "", false);
      }
    },
    changeSelect(value: string, setting: ITemplateElement) {
      // setting couldn't be empty
      if (!value) {
        (this.data as Record<string, unknown>)[setting.id.toString()] =
          setting.code === "SYSTEM_STATUS"
            ? setting.defaultValues.find((item) => item.value === "NORMAL")?.value ?? ""
            : setting.defaultValues?.[0]?.value ?? "";
      }
    },
    async resendCerts() {
      const urlCode = "VIPNET_URL";
      const loginCode = "VIPNET_LOGIN";
      const passwordCode = "VIPNET_PASSWORD";
      const items = this.templates.reduce((result: { [key: string]: ITemplateElement }, item: ISectionElement) => {
        item.settings.forEach((setting: ITemplateElement) => {
          if ([urlCode, loginCode, passwordCode, vipnetListCode, smevListCode].includes(setting.code)) {
            result[setting.code] = setting;
          }
        });
        return result;
      }, {});

      const urlValue = (this.data as { [key: string]: unknown })?.[items[urlCode]?.id || ""] || "";
      const loginValue = (this.data as { [key: string]: unknown })?.[items[loginCode]?.id || ""] || "";
      const passwordValue = (this.data as { [key: string]: unknown })?.[items[passwordCode]?.id || ""] || "";

      if (!urlValue || !loginValue || !passwordValue) {
        showNotification(
          `Для запроса сертификатов требуется заполнить поля: ${items[urlCode]?.name || ""}, ${items[loginCode]?.name || ""}, ${
            items[passwordCode]?.name || ""
          }`
        );
      }

      await this.getVipnetCertificates({ url: urlValue, login: loginValue, password: passwordValue });
    },
    reset() {
      this.data = { ...this.defaultData };
      this.key = uuid();
    },
    convertItems(items: { id: string; value: string; displayName?: string }[]) {
      return (items || []).map((item) => ({
        value: item.value,
        title: item.displayName || item.value,
      }));
    },
    handlePasswordChangeType(code: string) {
      this.passwordIsShowObject = {
        ...this.passwordIsShowObject,
        [code]: !this.passwordIsShowObject[code],
      };
    },
    getByCode(code: string) {
      return this.templates.reduce((result: { code: string; id: number } | null, item: { settings: { code: string; id: number }[] }) => {
        if (result) {
          return result;
        }

        return item.settings.find((setting: { code: string }) => setting.code === code) || null;
      }, null);
    },
    async handleCheckTsaUri() {
      const isUseTsaCode = "VIPNET_TSA_USE";
      const tsaUriCode = "VIPNET_TSA_URI";
      const useTsaObject = this.getByCode(isUseTsaCode);
      const tsaUriObject = this.getByCode(tsaUriCode);
      console.log(this.data);
      return (
        !useTsaObject?.id ||
        !tsaUriObject?.id ||
        !(this.data as Record<string, unknown>)[useTsaObject.id] ||
        ((this.data as Record<string, unknown>)[tsaUriObject.id] === (this.defaultData as Record<string, unknown>)[tsaUriObject.id] &&
          (this.data as Record<string, unknown>)[useTsaObject.id] === (this.defaultData as Record<string, unknown>)[useTsaObject.id]) ||
        (!!(this.data as Record<string, unknown>)[tsaUriObject.id] &&
          (await this.checkTsaUri((this.data as Record<string, unknown>)[tsaUriObject.id])))
      );
    },
    async handleSave() {
      if (!(await this.handleCheckTsaUri())) {
        showNotification("Сервер штампа времени не доступен, проверьте настройки.", NOTIFICATION_STATUS.ERROR);
        return;
      }

      this.onSave();
    },
    changeField(type: TYPES) {
      if (type !== TYPES.CRON) {
        return;
      }
      if (this.cronSettings.some((cron: string) => !validateCron(cron))) {
        showNotification("Проверьте правильность заполнения Cron выражения.", NOTIFICATION_STATUS.ERROR);
      }
    },
    async onSave() {
      try {
        if (!this.isValidLeftDaysValues) {
          showNotification('Проверьте правильность заполнения маски "DDDD:HH:MM"', NOTIFICATION_STATUS.ERROR);
          return;
        }
        if (this.cronSettings.some((cron: string) => !validateCron(cron))) {
          showNotification("Проверьте правильность заполнения Cron выражения.", NOTIFICATION_STATUS.ERROR);
          return;
        }
        const result = Object.entries(this.data).map(([id, value]) => ({
          id,
          value:
            this.isMultipleSelectType(this.codeData[id]) && Array.isArray(value)
              ? value.join(";")
              : this.isComplexType(this.codeData[id])
              ? JSON.stringify(value)
              : value,
        }));
        await this.saveElements(result);
        await this.getData().then(this.getFilesMetadata);
        showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        showNotification("Ошибка сохранения.", NOTIFICATION_STATUS.ERROR);
      }
    },
    async getData() {
      const data = await this.getDataRequest();
      const objectData = data.reduce((response: { [key: string]: string | boolean }, item: IValueElement) => {
        try {
          return {
            ...response,
            [item.id]: this.isMultipleSelectType(item.code as string)
              ? item.value.split(";").filter((item) => !!item)
              : this.isComplexType(item.code)
              ? JSON.parse(item.value) || []
              : item.value,
          };
        } catch (e) {
          return response;
        }
      }, {});
      this.codeData = data.reduce((response: { [key: string]: string | boolean }, item: IValueElement) => {
        return {
          ...response,
          [item.id]: item.code,
        };
      }, {});
      this.defaultData = { ...objectData };
      this.data = { ...objectData };
      this.key = uuid();
    },
    isShowPanel(templateCode: string): boolean {
      switch (templateCode) {
        case "GENERAL_SECTION":
          return this.isShowSYSTEM;
        case "vsh-container-worker-settings":
          return this.isShowCWS;
        case "vsh-dictionary-settings":
          return this.isShowDS;
        case "vsh-document-registration-settings":
          return this.isShowDRS;
        case "vsh-information-retrieval-settings":
          return this.isShowIRS;
        case "vsh-hduo-integration-settings":
          return this.isShowHDUOIS;
        case "vsh-filestorage-settings":
          return this.isShowHDStorageCHED;
        case "vsh-report-settings":
          return this.isShowRS;
        case "vsh-information-security-settings":
          return this.isShowIB;
        case "vsh-administration-settings":
          return this.isShowMAA;
        default:
          return true;
      }
    },
  },
  mounted() {
    this.getData()
      .then(this.getTemplates)
      .then(this.resendCerts)
      .then(() => {
        if (isAuthorityExist(this.user, authorities.INFORMATION_SECURITY_JOURNAL_LIST)) {
          this.getAuditTypesToNotify();
        }
      })
      .then(this.getFilesMetadata);
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "SettingsView", SettingsView as Module<unknown, unknown>);
  },
});
